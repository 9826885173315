export const getStepValidationNames = (step: number): string[] => {
   switch (step) {
      case 1:
         return ['date', 'address', 'teamId', 'startTime', 'endTime']
      case 2:
         return ['workItems']
      case 3:
         return ['consumptions', 'transport']
      case 4:
         return []
      case 5:
         return []
      case 6:
         return ['emailReceivers']
      default:
         return []
   }
}
