import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { DiaryInput } from '../../graphql/generated/schema'

type NumberInputProps = {
   label: string
   registerName: any
   placeholder: string
   classes: string
   control: Control<DiaryInput, any>
}
const NumberInput = ({ label, registerName, placeholder, classes, control }: NumberInputProps) => {
   return (
      <div className={`${classes}`}>
         <label className="block text-yellow-500 text-lg">
            {label}
            <Controller
               name={registerName}
               control={control}
               rules={{ required: 'Fyll i detta fält' }}
               render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <input
                     className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                     type="number"
                     onChange={(e) => {
                        onChange(parseInt(e.target.value))
                     }}
                     placeholder={placeholder}
                     value={value}
                  />
               )}
            />
         </label>
      </div>
   )
}

export default NumberInput
