import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import AuthContext from '../../authentication/AuthContextProvider'
import { IAuthenticateRequest } from '../../authentication/interfaces'
import { useTranslation } from 'react-i18next'
const Login = () => {
   const { t } = useTranslation()
   const auth = useContext(AuthContext)
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm<IAuthenticateRequest>()
   const onSubmit = async (data: IAuthenticateRequest) => {
      if (auth && auth.login) await auth.login(data)
   }
   return (
      <div className="grid-flow-row">
         <form onSubmit={handleSubmit(onSubmit)} className="px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
               <label className="block text-yellow-500 text-lg mb-2">{t('login.username')}</label>
               <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline"
                  type="email"
                  {...register('username', {
                     required: true,
                  })}
                  placeholder={t('login.username') ?? ''}
               />
               {errors.username && <p>{errors.username.message}</p>}
            </div>
            <div className="mb-4">
               <label className="block text-yellow-500 text-lg mb-2">{t('login.password')}</label>
               <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 mb-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                  type="password"
                  {...register('password', {
                     required: true,
                  })}
                  placeholder={t('login.password') ?? ''}
               />
               {errors.password && <p>{errors.password.message}</p>}
            </div>
            <button className="bg-yellow-500 hover:bg-yellow-600 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ">
               {t('buttons.login')}
            </button>
         </form>
      </div>
   )
}

export default Login
