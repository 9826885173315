import { MouseEventHandler } from 'react'

type ButtonProps = {
      text: string
      onClick: MouseEventHandler<HTMLButtonElement>
}

const SecondaryButton = ({ text, onClick }: ButtonProps) => {
      return (
            <button
                  className="bg-neutral-700 hover:bg-gray-600 text-yellow-500 min-w-fit w-20 font-bold py-2 px-4 rounded-lg border border-yellow-500 focus:outline-none focus:shadow-outline focus:bg-gray-600 mb-10 ml-4"
                  onClick={onClick}
            >
                  {text}
            </button>
      )
}

export default SecondaryButton
