import { t } from 'i18next'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Button from '../../shared/Button'
import StepTitle from '../../shared/StepTitle'
import { Control, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { DiaryInput } from '../../../graphql/generated/schema'
import SelfControlQuestion from '../../shared/SelfControlQuestion'
import { useTranslation } from 'react-i18next'

type SelfControlProps = {
   setValue: UseFormSetValue<DiaryInput>
   register: UseFormRegister<DiaryInput>
   control: Control<DiaryInput, any>
   errors: any
   watch: UseFormWatch<DiaryInput>
}

declare interface ISelfControlQuestion {
   id: number
   title: string
}

const SelfControl = ({ setValue, register, control, errors, watch }: SelfControlProps) => {
   const { t } = useTranslation()
   const [questions, setQuestions] = useState<ISelfControlQuestion[]>([])
   useEffect(() => {
      const coll = t('self_control_questions', { returnObjects: true })
      setQuestions(coll as ISelfControlQuestion[])
   }, [])

   return (
      <>
         {questions.map((q, index) => (
            <SelfControlQuestion setValue={setValue} register={register} index={index} watch={watch} questionId={q.id} label={q.title} classes="grid grid-cols-12 col-span-12 mb-2" />
         ))}
      </>
   )
}

export default SelfControl
