import { t } from 'i18next'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Button from '../../shared/Button'
import StepTitle from '../../shared/StepTitle'

type HeaderProps = {
   setStep: (nu: number) => void
   step: number
}
const Header = ({ setStep, step }: HeaderProps) => {
   const isLastStep = step === 6
   const [submitIsDisabled, setSubmitIsDisabled] = useState(true)
   const getPageTitle = (step: number): string => {
      switch (step) {
         case 1:
            return t('headers.newtimereport')
         case 2:
            return t('headers.workperformed')
         case 3:
            return t('headers.consumption')
         case 4:
            return t('headers.selfcontrol')
         case 5:
            return t('headers.additionalinfo')
         case 6:
            return t('headers.submitdiary')
         default:
            return ''
      }
   }

   useEffect(() => {
      //The user MAY be to quick to hit all the changes,
      //when we are at the last step, set a timeout so
   }, [isLastStep])

   return (
      <div className="grid col-span-12 mt-10 grid-cols-12 sticky top-0 w-full bg-neutral-800 py-10 z-20 -ml-1  ">
         <StepTitle title={t('headers.diary')} page={getPageTitle(step)}></StepTitle>
         <div className="col-span-4 justify-self-end">{!isLastStep && <Button text={t('buttons.next')} onClick={() => setStep(step + 1)} />}</div>
      </div>
   )
}

export default Header
