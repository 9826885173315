export const LogoBanner = () => {
   return (
      <div
         className="bg-yellow-500 w-screen h-24 fixed
             inset-x-0
             bottom-0 align-middle"
      >
         <img src="/logo.png" className="h-6 mx-auto" style={{ marginTop: '32px' }} />
      </div>
   )
}
