import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { DiaryInput, TeamMember } from '../../graphql/generated/schema'
import { useEffect } from 'react'
import { t } from 'i18next'

type dropDownProps = {
   label: string
   registerName: any
   classes: string
   options:
      | {
           __typename?: 'Team' | undefined
           id: number
           name: string
           teamMembers: {
              __typename?: 'TeamMember' | undefined
              id: number
              firstName: string
              lastName: string
              teamId: number
           }[]
        }[]
      | undefined
   defaultOptionText: string
   errors: any
   control: Control<DiaryInput, any>
   watch: UseFormWatch<DiaryInput>
   setValue: UseFormSetValue<DiaryInput>
}

const TeamDropDown = ({ label, registerName, classes, options, defaultOptionText, errors, control, watch, setValue }: dropDownProps) => {
   type value = {
      __typename?: 'Team' | undefined
      id: number
      name: string
      teamMembers: {
         __typename?: 'TeamMember' | undefined
         id: number
         firstName: string
         lastName: string
         teamId: number
      }[]
   }

   useEffect(() => {
      let team = options?.find((x) => x?.id == watch('teamId'))
      if (team) {
         let updatedValue: value = { ...team }
         let teamMembersCopy: Array<TeamMember> = []
         delete updatedValue.__typename
         updatedValue.teamMembers.map((x) => {
            let copy = { ...x }
            delete copy.__typename
            teamMembersCopy.push(copy)
         })
         updatedValue.teamMembers = teamMembersCopy
         setValue('team', updatedValue)
      }
   }, [watch('teamId')])
   return (
      <>
         <div className={`${classes} flex`}>
            <label className="w-full block text-yellow-500 text-lg">
               {label}
               <Controller
                  name={registerName}
                  control={control}
                  rules={{ required: 'Fyll i detta fält' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                     <select
                        placeholder={`${t('placeholders.chooseteam')}`}
                        onChange={(e) => {
                           if (e.target.value === defaultOptionText) onChange(undefined)
                           else onChange(parseInt(e.target.value))
                        }}
                        value={value}
                        className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                     >
                        <option className="text-neutral-400">{defaultOptionText}</option>
                        {options?.map((x, i) => {
                           return (
                              <option value={x.id} key={i}>
                                 {x.name}
                              </option>
                           )
                        })}
                     </select>
                  )}
               />
               {errors[registerName] && <p className="text-red-500">{errors[registerName]?.message ?? 'Fyll i detta fält'}</p>}
            </label>
            <span className="text-yellow-500 w-0">
               <ChevronDownIcon className={`w-8 h-8 ${label !== '' ? 'relative top-8 -left-10' : 'relative top-1 -left-10'}`}></ChevronDownIcon>
            </span>
         </div>
      </>
   )
}

export default TeamDropDown
