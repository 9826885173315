import { UseFormRegister } from 'react-hook-form'
import { t } from 'i18next'
import { DiaryInput } from '../../graphql/generated/schema'

type datePickerProps = {
   label: string
   register: UseFormRegister<DiaryInput>
   registerName: any
   classes: string
   errors: any
}

const DatePicker = ({ label, register, registerName, classes, errors }: datePickerProps) => {
   return (
      <div className={classes}>
         <label className="text-yellow-500 text-lg mb-2 flex">{label}</label>
         <input
            className="text-center sm:text-left h-10 w-6/12 shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
            type={'date'}
            {...register(registerName, {
               required: 'Välj ett datum',
            })}
            placeholder={`${t('placeholders.date')}`}
         />
         {errors[registerName] && <p className="text-red-500">{errors[registerName]?.message ?? 'Fyll i detta fält'}</p>}
      </div>
   )
}

export default DatePicker
