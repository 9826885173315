import Button from './Button'
type ErrorModalProps = {
   setShow: React.Dispatch<React.SetStateAction<boolean>>
   title: string
   subTitle: string
   primaryBtnText: string
   zIndex: string
}
const ErrorModal = ({ setShow, title, subTitle, primaryBtnText, zIndex }: ErrorModalProps) => {
   return (
      <>
         <div
            data-modal-backdrop="static"
            tabIndex={-1}
            className={`fixed top-48 left-0 right-0 ${zIndex} w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full`}
         >
            <div className="relative w-full h-full max-w-2xl md:h-auto">
               <div className="relative bg-neutral-700 rounded-lg shadow">
                  <div className="flex items-start justify-between p-4 border-b rounded-t ">
                     <h3 className="text-xl font-semibold border-white text-white dark:text-white">{title}</h3>
                  </div>
                  <div className="p-6 space-y-6">
                     <p className="text-base leading-relaxed border-white text-white ">{subTitle}</p>
                  </div>
                  <div className="p-6 space-x-2 border-t">
                     <Button
                        text={primaryBtnText}
                        onClick={() => {
                           setShow(false)
                        }}
                     />
                  </div>
               </div>
            </div>
         </div>
         <div modal-backdrop="" className=" bg-neutral-700 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30"></div>
      </>
   )
}

export default ErrorModal
