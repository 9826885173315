import { t } from 'i18next'
import React, { Dispatch, SetStateAction, useState } from 'react'
import {} from 'react-hook-form'
import { PhotoInput } from '../../graphql/generated/schema'
import { RemoveItemFromCollection } from '../../helpers/dataHelper'

type FileInputProps = {
   coords: GeolocationCoordinates | undefined
   setUploadedPictures: Dispatch<SetStateAction<PhotoInput[]>>
   upLoadedPictures: PhotoInput[]
}
const FileInput = ({ coords, setUploadedPictures, upLoadedPictures }: FileInputProps) => {
   const convertToBase64 = (file: File) => {
      return new Promise((resolve, reject) => {
         const fileReader = new FileReader()
         fileReader.readAsDataURL(file)
         fileReader.onload = () => {
            resolve(fileReader.result)
         }
         fileReader.onerror = (error) => {
            reject(error)
         }
      })
   }
   const [file, setFile] = useState<string>()
   const handleFileUpload = async (e: any) => {
      for (let index = 0; index < e.target.files.length; index++) {
         const file = e.target.files[index]
         const base64 = (await convertToBase64(file)) as string
         setFile(base64)
         const picture = { diaryId: undefined, id: undefined, geolocation: `Lat: ${coords?.latitude},Lon: ${coords?.longitude}`, picture: base64 }
         setUploadedPictures((oldArray) => [...oldArray, picture])
      }
   }
   const removePicture = (item: PhotoInput) => {
      RemoveItemFromCollection(setUploadedPictures, upLoadedPictures, item)
   }
   return (
      <>
         <ul className="col-span-12 pl-5 pr-5">
            {upLoadedPictures?.map((x) => (
               <>
                  <li className="grid grid-cols-12 mt-6 ">
                     <div className="col-span-12 flex flex-col">
                        <img src={x?.picture ?? ''} alt="screenshot" className="border border-yellow-500" />

                        <button
                           className="col-span-12 bg-red-700 hover:bg-yellow-600 text-gray-700 min-w-fit  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                           onClick={() => {
                              removePicture(x)
                           }}
                           type="button"
                        >
                           {t('buttons.removephoto')}
                        </button>
                     </div>
                  </li>
               </>
            ))}
         </ul>
         <div className="col-span-12 flex flex-col pl-5 pr-5">
            {/* <input multiple type="file" name="myFile" accept=".jpeg, .png, .jpg" onChange={(e) => handleFileUpload(e)} /> */}
            <label className="block mb-2 text-lg font-medium text-yellow-500 dark:text-white" htmlFor="multiple_files">
               {t('labels.uploadphotos')}
            </label>
            <input
               onChange={(e) => handleFileUpload(e)}
               className="block w-full text-lg  text-yellow-500 border  rounded-lg cursor-pointer border-yellow-500 "
               id="multiple_files"
               type="file"
               multiple
            ></input>
         </div>
      </>
   )
}

export default FileInput
