import { useEffect, useState } from 'react'

export const AppVersion = () => {
      const [version, setVersion] = useState('')

      useEffect(() => {
            getVersion()
      }, [])

      const getVersion = async () => {
            let response
            try {
                  response = await fetch(
                        `${process.env.REACT_APP_API_BASE_URL}/info/getversion`,
                        {
                              mode: 'cors',
                              method: 'GET',
                        }
                  )
            } catch (error) {
                  console.log('There was an error', error)
            }

            if (response?.ok) {
                  let v = await response.text()
                  setVersion(v)
            } else {
                  return
            }
      }

      return <span className="text-white text-xs">{version}</span>
}

export default AppVersion
