import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from './AuthContextProvider'
export type ProtectedRouteProps = {
      children: JSX.Element
}
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
      const { user } = useContext(AuthContext)
      if (user) {
            return children
      }
      return <Navigate to="/Login"></Navigate>
}
export default ProtectedRoute
