import { MouseEventHandler } from 'react'

type AddPhotoBtnProps = {
   text: string
   onClick: MouseEventHandler<HTMLButtonElement>
   disabled: boolean
}

const AddPhotoBtn = ({ text, onClick, disabled }: AddPhotoBtnProps) => {
   return (
      <button
         disabled={disabled}
         className="col-span-12 bg-yellow-500 hover:bg-yellow-600 text-gray-700 min-w-fit  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
         onClick={onClick}
         type="button"
      >
         {text}
      </button>
   )
}

export default AddPhotoBtn
