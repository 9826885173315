import '../../../App.css'
import { useContext, useState } from 'react'
import AuthContext from '../../../authentication/AuthContextProvider'
import { t } from 'i18next'
import {
      Control,
      UseFormRegister,
      UseFormSetValue,
      UseFormWatch,
} from 'react-hook-form'
import CheckBox from '../../shared/CheckBox'
import { DiaryInput } from '../../../graphql/generated/schema'
import NumberInput from '../../shared/NumberInput'
import ConsumptionMassAndTon from './ConsumptionMassAndTon'
import ConsumptionTransport from './ConsumptionTransport'
type ConsumptionProps = {
      setValue: UseFormSetValue<DiaryInput>
      register: UseFormRegister<DiaryInput>
      control: Control<DiaryInput, any>
      errors: any
      watch: UseFormWatch<DiaryInput>
}
const Consumption = ({
      setValue,
      register,
      control,
      errors,
      watch,
}: ConsumptionProps) => {
      const { user } = useContext(AuthContext)

      return (
            <>
                  <ConsumptionMassAndTon
                        control={control}
                        register={register}
                        errors={errors}
                  />
                  <ConsumptionTransport
                        control={control}
                        register={register}
                        errors={errors}
                  />
                  <div className="col-span-12 grid gap-4 mt-5 mb-10">
                        <h2 className="text-white text-xl font-bold">
                              {t('headers.general')}
                        </h2>
                        <CheckBox
                              label={t('labels.milling')}
                              registerName={'milling'}
                              classes={'col-span-12'}
                              watch={watch}
                              setValue={setValue}
                        />
                        {watch('milling') && (
                              <NumberInput
                                    label={t('labels.millingSquaremeter')}
                                    registerName={'millingSquaremeter'}
                                    placeholder={''}
                                    classes={'col-span-1'}
                                    control={control}
                              />
                        )}
                        <CheckBox
                              label={t('labels.sweeper')}
                              registerName={'sweeper'}
                              classes={'col-span-12'}
                              watch={watch}
                              setValue={setValue}
                        />
                        {watch('sweeper') && (
                              <NumberInput
                                    label={t('labels.hours')}
                                    registerName={'sweeperTime'}
                                    placeholder={''}
                                    classes={'col-span-1'}
                                    control={control}
                              />
                        )}
                        <CheckBox
                              label={t('labels.truckMountedAttenuator')}
                              registerName={'truckMountedAttenuator'}
                              classes={'col-span-12'}
                              watch={watch}
                              setValue={setValue}
                        />
                        {watch('truckMountedAttenuator') && (
                              <NumberInput
                                    label={t('labels.hours')}
                                    registerName={'truckMountedAttenuatorTime'}
                                    placeholder={''}
                                    classes={'col-span-1'}
                                    control={control}
                              />
                        )}
                        <CheckBox
                              label={t('labels.trafficGuards')}
                              registerName={'trafficGuards'}
                              classes={'col-span-12'}
                              watch={watch}
                              setValue={setValue}
                        />
                        {watch('trafficGuards') && (
                              <NumberInput
                                    label={t('labels.hours')}
                                    registerName={'trafficGuardsTime'}
                                    placeholder={''}
                                    classes={'col-span-1'}
                                    control={control}
                              />
                        )}
                  </div>
            </>
      )
}

export default Consumption
