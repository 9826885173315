import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { CheckIcon } from '@heroicons/react/20/solid'
import { DiaryInput } from '../../graphql/generated/schema'

type checkBoxProps = {
      label: string
      registerName: any
      classes: string
      watch: UseFormWatch<DiaryInput>
      setValue: UseFormSetValue<DiaryInput>
}

const CheckBox = ({
      label,
      registerName,
      classes,
      watch,
      setValue,
}: checkBoxProps) => {
      // Check this link to get the integration to work https://codesandbox.io/s/react-hook-form-custom-checkbox-yncp5?file=/src/App.js

      return (
            <div className={`${classes} flex gap-2 items-center`}>
                  <label
                        className="block text-yellow-500 text-lg order-2 col-span-11"
                        onClick={() => {
                              watch(registerName)
                                    ? setValue(registerName, false)
                                    : setValue(registerName, true)
                        }}
                  >
                        {label}
                  </label>
                  <span
                        className={`block order-1 w-4 h-4 border rounded border-yellow-500 col-span-1  
                        ${
                              watch(registerName)
                                    ? 'bg-yellow-500'
                                    : 'bg-neutral-700'
                        }
                        `}
                        onClick={() => {
                              watch(registerName)
                                    ? setValue(registerName, false)
                                    : setValue(registerName, true)
                        }}
                  >
                        {watch(registerName) && <CheckIcon></CheckIcon>}
                  </span>
            </div>
      )
}

export default CheckBox
