import { Control, useFieldArray, UseFormSetValue } from 'react-hook-form'
import { DiaryInput } from '../../graphql/generated/schema'

type positionProps = {
   label: string
   placeholder: string
   classes: string
   coords: GeolocationCoordinates | undefined
   setValue: UseFormSetValue<DiaryInput>
}
const Position = ({ label, placeholder, classes, setValue, coords }: positionProps) => {
   return (
      <div className={classes}>
         {coords && (
            <label className="block text-yellow-500 text-lg mb-2">
               {label}
               <input
                  disabled
                  className="w-full  shadow appearance-none border rounded py-2 px-3 mb-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                  type="text"
                  onChange={() => {
                     setValue(`photos.${0}.geolocation`, `Lat: ${coords?.latitude},Lon: ${coords?.longitude}`)
                  }}
                  placeholder={placeholder}
                  value={`Lat: ${coords?.latitude},Lon: ${coords?.longitude}`}
               ></input>
            </label>
         )}
      </div>
   )
}

export default Position
