import { useContext, useState } from 'react'
import { t } from 'i18next'
import { Control, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import AuthContext from '../../../authentication/AuthContextProvider'
import DatePicker from '../../shared/DatePicker'
import TimePicker from '../../shared/TimePicker'
import TextInput from '../../shared/TextInput'
import { DiaryInput, useGetMetaDataOptionsQuery } from '../../../graphql/generated/schema'
import RequiredTextInput from '../../shared/RequiredTextInput'
import TeamDropDown from '../../shared/TeamDropDown'

type NewTimeReportFormProps = {
   register: UseFormRegister<DiaryInput>
   setValue: UseFormSetValue<DiaryInput>
   control: Control<DiaryInput, any>
   watch: UseFormWatch<DiaryInput>
   errors: any
}
const NewTimeReportForm = ({ register, setValue, control, watch, errors }: NewTimeReportFormProps) => {
   const user = useContext(AuthContext)
   const [extraWorker, setExtraWorker] = useState(false)
   const [exceptionTimeReport, setExceptionTimeReport] = useState(false)
   const { loading, error, data } = useGetMetaDataOptionsQuery()
   return (
      <>
         <DatePicker label={t('labels.date')} register={register} registerName={'date'} classes={'col-span-12'} errors={errors} />
         <TextInput label={t('labels.account')} register={register} registerName={'account'} placeholder={''} classes={'col-span-12'} />
         <RequiredTextInput label={t('labels.address')} register={register} registerName={'address'} placeholder={''} classes={'col-span-12'} errors={errors} />
         <TextInput label={t('labels.customer')} register={register} registerName={'customer'} placeholder={''} classes={'col-span-12'} />
         <TeamDropDown
            label={t('labels.team')}
            registerName={'teamId'}
            classes={'col-span-12'}
            options={data?.diaryMetaOptions.teams}
            defaultOptionText={t('placeholders.chooseteam')}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
         />
         {/* <ExtraWorker
                        extraWorker={extraWorker}
                        setExtraWorker={setExtraWorker}
                  ></ExtraWorker> */}
         {/* {extraWorker && (
                        // TODO: Implementera dynamisk hämtning av personer från db
                        <AddInputField
                              setValue={setValue}
                              control={control}
                              registerName={'extraworkers'}
                              label={''}
                              register={register}
                              placeholder={''}
                              liClass={''}
                              component={
                                    <TeamDropDown
                                          label={''}
                                          registerName={'extraworker'}
                                          classes={'col-span-12 mt-4'}
                                          options={undefined}
                                          defaultOptionText={t(
                                                'placeholders.chooseextraworker'
                                          )}
                                          errors={errors}
                                          control={control}
                                          watch={watch}
                                          setValue={setValue}
                                    ></TeamDropDown>
                              }
                        ></AddInputField>
                  )} */}
         <div className="col-span-12 mt-10">
            <h2 className="text-xl font-bold text-white">{t('headers.teamtime')}</h2>
            <p className="italic text-white">({t('paragraphs.sametimeforall')})</p>
         </div>
         <div className="col-span-12 flex gap-6 justify-between">
            <TimePicker label={t('labels.starttime')} registerName="startTime" classes={'w-5/12 sm:w-1/2'} errors={errors} control={control} />
            <TimePicker label={t('labels.endtime')} registerName="endTime" classes={'w-5/12 sm:w-1/2'} errors={errors} control={control} />
         </div>
         {/* <CheckBox
                        label={t('labels.exceptionTimeReport')}
                        registerName={'exceptionTimeReport'}
                        classes={'col-span-12'}
                        checked={exceptionTimeReport}
                        setChecked={setExceptionTimeReport}
                        setValue={setValue}
                  /> */}
      </>
   )
}

export default NewTimeReportForm
