import { t } from 'i18next'
import React, { useEffect } from 'react'
import { Control, useFieldArray, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { DiaryInput } from '../../../graphql/generated/schema'
import AddInputFieldBtn from '../../shared/AddInputFieldBtn'

type ConsumptionTransportProps = {
   register: UseFormRegister<DiaryInput>
   control: Control<DiaryInput, any>
   errors: any
}
const ConsumptionTransport = ({ control, register, errors }: ConsumptionTransportProps) => {
   const { fields, append } = useFieldArray({
      control,
      name: 'transport',
   })
   return (
      <>
         <ul className="col-span-12">
            {fields?.map((field, index) => (
               <>
                  <li className="grid grid-cols-12 mt-6" key={field.id}>
                     <div className={'col-span-6'}>
                        <label className="block text-yellow-500 text-lg">
                           {t('labels.transport')}
                           <input
                              {...register(`transport.${index}.name`)}
                              className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                           />
                        </label>
                        {errors?.transport && errors?.transport[index]?.name && (
                           <p className="text-red-500">{errors?.transport[index]?.name?.message ?? 'Fyll i detta fält'}</p>
                        )}
                     </div>
                     <div className={'col-span-4 col-start-9'}>
                        <label className="block text-yellow-500 text-lg">
                           {t('labels.hours')}
                           <input
                              {...register(`transport.${index}.hours`, {
                                 valueAsNumber: true,
                              })}
                              className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                              type="number"
                           />
                        </label>
                        {errors?.transport && errors?.transport[index]?.hours && (
                           <p className="text-red-500">{errors?.transport[index]?.hours?.message ?? 'Fyll i detta fält'}</p>
                        )}
                     </div>
                  </li>
               </>
            ))}
         </ul>
         <AddInputFieldBtn
            label={t('labels.newLine')}
            classes={''}
            onClick={() => {
               append({
                  id: undefined,
                  name: undefined,
                  diaryId: undefined,
                  hours: undefined,
               })
            }}
         ></AddInputFieldBtn>
      </>
   )
}

export default ConsumptionTransport
