import { ReactNode } from 'react'
import '../../App.css'
import { LanguagePicker } from '../languagepicker/LanguagePicker'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AppVersion from '../appversion/AppVersion'

type LayoutProps = {
   children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
   return (
      <>
         <div className="container mx-auto text-right">
            {/* <LanguagePicker /> */}
            <AppVersion />
         </div>
         <div className="container mx-auto">{children}</div>
         <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
         />
      </>
   )
}
export default Layout
