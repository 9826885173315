import React, { useCallback, useRef, useState } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import Webcam from 'react-webcam'
import { CameraIcon, ArrowPathIcon } from '@heroicons/react/24/solid'
import { DiaryInput } from '../../graphql/generated/schema'
type CameraProps = {
   cameraEnabled: boolean
   setCameraEnabled: (value: boolean) => void
   setValue: UseFormSetValue<DiaryInput>
   coords: GeolocationCoordinates | undefined
   index: number
   watch: UseFormWatch<DiaryInput>
}
const Camera = ({ cameraEnabled, setCameraEnabled, setValue, coords, index, watch }: CameraProps) => {
   const [imageSrc, setImgSrc] = useState<string | null>(null)
   const webcamRef = useRef<Webcam>(null)

   const capture = useCallback(() => {
      if (webcamRef.current) {
         const src = webcamRef.current.getScreenshot()
         setImgSrc(src)
         setValue(`photos.${index}`, {
            diaryId: undefined,
            id: undefined,
            geolocation: `Lat: ${coords?.latitude},Lon: ${coords?.longitude}`,
            picture: src ? src : '',
         })
      }
   }, [webcamRef, setImgSrc])

   const FACING_MODE_USER = 'user'
   const FACING_MODE_ENVIRONMENT = 'environment'
   const videoConstraints = {
      facingMode: FACING_MODE_USER,
   }
   const [facingMode, setFacingMode] = useState(FACING_MODE_USER)
   return (
      <div>
         {!watch(`photos.${index}.picture`) && (
            <div className="flex flex-col">
               <Webcam
                  height={'100%'}
                  width={'100%'}
                  mirrored={facingMode === FACING_MODE_USER}
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                     ...videoConstraints,
                     facingMode,
                  }}
               />
               <div className="flex justify-around mt-2">
                  <button
                     onClick={() => {
                        setFacingMode((prevState) => (prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER))
                     }}
                     className="mt-2 "
                  >
                     <ArrowPathIcon height={24} width={24} className=" text-yellow-500"></ArrowPathIcon>
                  </button>
                  <button
                     type="button"
                     onClick={() => {
                        capture()
                        setCameraEnabled(false)
                     }}
                     className="mt-2 "
                  >
                     <CameraIcon height={24} width={24} className=" text-yellow-500"></CameraIcon>
                  </button>
               </div>
            </div>
         )}
         :{' '}
         {imageSrc && watch(`photos.${index}.picture`) && (
            <img src={watch(`photos.${index}.picture`) ?? ''} alt="screenshot" className="border border-yellow-500" />
         )}
      </div>
   )
}

export default Camera
