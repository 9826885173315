import { UseFormRegister } from 'react-hook-form'
import { DiaryInput } from '../../graphql/generated/schema'

type requiredTextInputProps = {
   label: string
   register: UseFormRegister<DiaryInput>
   registerName: any
   placeholder: string
   classes: string
   errors: any
}
const RequiredTextInput = ({ label, register, registerName, placeholder, classes, errors }: requiredTextInputProps) => {
   return (
      <div className={`${classes}`}>
         <label className="block text-yellow-500 text-lg">
            {label}
            <input
               className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
               type="text"
               {...register(registerName, {
                  required: 'Fyll i detta fält',
               })}
               placeholder={placeholder}
            />
         </label>
         {errors[registerName] && <p className="text-red-500">{errors[registerName]?.message ?? 'Fyll i detta fält'}</p>}
      </div>
   )
}

export default RequiredTextInput
