import dayjs from 'dayjs'
import { t } from 'i18next'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { DiaryInput } from '../../graphql/generated/schema'

type TimeSpan = {
   time: string
   value: string
}

type timePickerProps = {
   label: string
   registerName: any
   classes: string
   errors: any
   control: Control<DiaryInput, any>
}
const TimePicker = ({ label, registerName, classes, errors, control }: timePickerProps) => {
   const getTimeCollection = (): TimeSpan[] => {
      let collection = []
      for (let index = 0; index < 24; index++) {
         let hour = String(index).padStart(2, '0')
         //            "startTime": "PT7H",
         //            "endTime": "PT16H30M"
         collection.push({
            value: `PT${index}H`,
            time: `${hour}:00`,
         })
         collection.push({
            value: `PT${index}H30M`,
            time: `${hour}:30`,
         })
      }
      return collection
   }
   let timeCollection = getTimeCollection()
   return (
      <div className={`${classes}`}>
         <label className="block text-yellow-500 text-lg">{label}</label>
         <Controller
            name={registerName}
            control={control}
            rules={{ required: 'Fyll i detta fält' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
               <select
                  placeholder={`${t('placeholders.time')}`}
                  onChange={onChange}
                  value={value}
                  className="text-center sm:text-left h-10 shadow appearance-none border rounded w-full py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
               >
                  {timeCollection.map((timeSpan, index) => (
                     <option key={index} value={`${timeSpan.value}`}>
                        {timeSpan.time}
                     </option>
                  ))}
               </select>
            )}
         />
         {errors[registerName] && <p className="text-red-500">{errors[registerName]?.message ?? 'Fyll i detta fält'}</p>}
      </div>
   )
}

export default TimePicker
