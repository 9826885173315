import { useEffect } from 'react'
import Button from './Button'

type geoPermisionModalProps = {
      setIsPositionModalVisible: React.Dispatch<React.SetStateAction<boolean>>
      title: string
      subTitle: string
      primaryBtnText: string
}
const GeoPermissionModal = ({
      setIsPositionModalVisible,
      title,
      subTitle,
      primaryBtnText,
}: geoPermisionModalProps) => {
      useEffect(() => {})
      return (
            <div className="z-10 blur-none bg-neutral-700 border border-white text-white p-6 rounded text-center min-w-xs max-w-lg absolute top-40 ml-auto mr-auto left-10 right-10">
                  <h3 className="text-2xl font-medium">{title}</h3>
                  <p className="text-lg font-extralight">{subTitle}</p>
                  <div className="pt-6 flex justify-evenly">
                        <Button
                              text={primaryBtnText}
                              onClick={() => {
                                    setIsPositionModalVisible(false)
                              }}
                        />
                  </div>
            </div>
      )
}

export default GeoPermissionModal
