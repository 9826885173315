import AddPhotoBtn from '../../shared/AddPhotoBtn'
import { t } from 'i18next'
import { Control, useFieldArray, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Dispatch, SetStateAction, useContext, useState } from 'react'
import AuthContext from '../../../authentication/AuthContextProvider'
import Position from '../../shared/Position'
import GeoPermissionModal from '../../shared/GeoPermissionModal'
import { useGeolocated } from 'react-geolocated'
import Camera from '../../shared/Camera'
import TextAreaNonReq from '../../shared/TextAreaNonReq'
import Modal from '../../shared/Modal'
import { DiaryInput, PhotoInput } from '../../../graphql/generated/schema'
import Button from '../../shared/Button'
import FileInput from '../../shared/FileInput'
type AdditionalInfoProps = {
   register: UseFormRegister<DiaryInput>
   setValue: UseFormSetValue<DiaryInput>
   control: Control<DiaryInput, any>
   watch: UseFormWatch<DiaryInput>
   setUploadedPictures: Dispatch<SetStateAction<PhotoInput[]>>
   upLoadedPictures: PhotoInput[]
}
const AdditionalInfo = ({ register, setValue, control, watch, setUploadedPictures, upLoadedPictures }: AdditionalInfoProps) => {
   const { user } = useContext(AuthContext)
   const [showLocation, setShowLocation] = useState<boolean>(false)
   const [cameraEnabled, setCameraEnabled] = useState<boolean>(false)

   const [isPositionModalVisible, setIsPositionModalVisible] = useState<boolean>(false)

   const { coords, isGeolocationEnabled } = useGeolocated({
      positionOptions: {
         enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
   })
   const { fields, append, remove } = useFieldArray({
      control,
      name: 'photos',
   })

   return (
      <>
         {isPositionModalVisible ? (
            <GeoPermissionModal
               setIsPositionModalVisible={setIsPositionModalVisible}
               title={t('paragraphs.sharelocation')}
               subTitle={t('paragraphs.sharelocationinstructions')}
               primaryBtnText={t('buttons.ok')}
            ></GeoPermissionModal>
         ) : (
            <></>
         )}

         <div className={`${isPositionModalVisible && 'blur'}  pl-5 pr-5 col-span-12 grid grid-cols-12 gap-6`}>
            <TextAreaNonReq
               label={t('labels.deviation')}
               register={register}
               registerName={'deviation'}
               placeholder={''}
               classes={'col-span-12'}
            ></TextAreaNonReq>

            <TextAreaNonReq label={t('labels.comment')} register={register} registerName={'comment'} placeholder={''} classes={'col-span-12'}></TextAreaNonReq>
            <ul className="col-span-12">
               <Position label={t('labels.position')} placeholder={''} classes={''} coords={coords} setValue={setValue} />
               {fields?.map((field, index) => (
                  <>
                     <li className="grid grid-cols-12 mt-6 " key={field.id}>
                        {showLocation && (
                           <div className="col-span-12 flex flex-col">
                              <Camera
                                 setCameraEnabled={(value) => setCameraEnabled(value)}
                                 cameraEnabled={cameraEnabled}
                                 coords={coords}
                                 setValue={setValue}
                                 index={index}
                                 watch={watch}
                              ></Camera>
                           </div>
                        )}
                        <AddPhotoBtn
                           disabled={false}
                           onClick={(e) => {
                              e.preventDefault()
                              !isGeolocationEnabled ? setIsPositionModalVisible(true) : setShowLocation(true)
                              setCameraEnabled(true)
                              setValue(`photos.${index}`, {
                                 diaryId: undefined,
                                 id: undefined,
                                 geolocation: `Lat: ${coords?.latitude},Lon: ${coords?.longitude}`,
                                 picture: '',
                              })
                           }}
                           text={showLocation ? t('buttons.newphoto') : t('buttons.addphoto')}
                        ></AddPhotoBtn>

                        <button
                           className="col-span-12 bg-red-700 hover:bg-yellow-600 text-gray-700 min-w-fit  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                           onClick={() => {
                              remove(field.id)
                           }}
                           type="button"
                        >
                           {t('buttons.removephoto')}
                        </button>
                     </li>
                  </>
               ))}
            </ul>

            <AddPhotoBtn
               disabled={cameraEnabled}
               onClick={(e) => {
                  e.preventDefault()
                  !isGeolocationEnabled ? setIsPositionModalVisible(true) : setShowLocation(true)
                  setCameraEnabled(true)
                  append({
                     diaryId: undefined,
                     id: undefined,
                     geolocation: undefined,
                     picture: undefined,
                  })
               }}
               text={t('buttons.addphoto')}
            ></AddPhotoBtn>
         </div>

         <FileInput coords={coords} setUploadedPictures={setUploadedPictures} upLoadedPictures={upLoadedPictures} />
      </>
   )
}

export default AdditionalInfo
