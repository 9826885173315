import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  TimeSpan: any;
};

/** Konto/AO */
export type Account = {
  __typename?: 'Account';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export enum Answer {
  Deviation = 'DEVIATION',
  NotApplicable = 'NOT_APPLICABLE',
  Ok = 'OK'
}

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

/** Förbrukning */
export type Consumption = {
  __typename?: 'Consumption';
  diaryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  ton?: Maybe<Scalars['Decimal']>;
};

/** Förbrukning */
export type ConsumptionFilterInput = {
  and?: InputMaybe<Array<ConsumptionFilterInput>>;
  diaryId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConsumptionFilterInput>>;
  ton?: InputMaybe<DecimalOperationFilterInput>;
};

/** Förbrukning */
export type ConsumptionInput = {
  diaryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  ton?: InputMaybe<Scalars['Decimal']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  neq?: InputMaybe<Scalars['Decimal']>;
  ngt?: InputMaybe<Scalars['Decimal']>;
  ngte?: InputMaybe<Scalars['Decimal']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']>>>;
  nlt?: InputMaybe<Scalars['Decimal']>;
  nlte?: InputMaybe<Scalars['Decimal']>;
};

/** Dagbok */
export type Diary = {
  __typename?: 'Diary';
  account?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  consumptions?: Maybe<Array<Consumption>>;
  createdBy: Scalars['Int'];
  /** Beställare/Kund */
  customer?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  deviation?: Maybe<Scalars['String']>;
  emailReceivers?: Maybe<Array<Scalars['String']>>;
  endTime: Scalars['TimeSpan'];
  exceptionTimeReport?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** Fräsning */
  milling?: Maybe<Scalars['Boolean']>;
  millingSquaremeter?: Maybe<Scalars['Decimal']>;
  photos?: Maybe<Array<Photo>>;
  selfControls?: Maybe<Array<SelfControl>>;
  startTime: Scalars['TimeSpan'];
  /** Sopmaskin */
  sweeper?: Maybe<Scalars['Boolean']>;
  /** SopmaskinTid */
  sweeperTime?: Maybe<Scalars['Float']>;
  team: Team;
  teamId: Scalars['Int'];
  timeReports: Array<TimeReport>;
  trafficGuards?: Maybe<Scalars['Boolean']>;
  trafficGuardsTime?: Maybe<Scalars['Float']>;
  transport?: Maybe<Array<Transport>>;
  /** TMA */
  truckMountedAttenuator?: Maybe<Scalars['Boolean']>;
  /** TMATid */
  truckMountedAttenuatorTime?: Maybe<Scalars['Float']>;
  workItems?: Maybe<Array<WorkItem>>;
};

/** Dagbok */
export type DiaryFilterInput = {
  account?: InputMaybe<StringOperationFilterInput>;
  address?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<DiaryFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  consumptions?: InputMaybe<ListFilterInputTypeOfConsumptionFilterInput>;
  createdBy?: InputMaybe<IntOperationFilterInput>;
  /** Beställare/Kund */
  customer?: InputMaybe<StringOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  deviation?: InputMaybe<StringOperationFilterInput>;
  emailReceivers?: InputMaybe<ListStringOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  exceptionTimeReport?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  /** Fräsning */
  milling?: InputMaybe<BooleanOperationFilterInput>;
  millingSquaremeter?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<DiaryFilterInput>>;
  photos?: InputMaybe<ListFilterInputTypeOfPhotoFilterInput>;
  selfControls?: InputMaybe<ListFilterInputTypeOfSelfControlFilterInput>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  /** Sopmaskin */
  sweeper?: InputMaybe<BooleanOperationFilterInput>;
  /** SopmaskinTid */
  sweeperTime?: InputMaybe<FloatOperationFilterInput>;
  team?: InputMaybe<TeamFilterInput>;
  teamId?: InputMaybe<IntOperationFilterInput>;
  timeReports?: InputMaybe<ListFilterInputTypeOfTimeReportFilterInput>;
  trafficGuards?: InputMaybe<BooleanOperationFilterInput>;
  trafficGuardsTime?: InputMaybe<FloatOperationFilterInput>;
  transport?: InputMaybe<ListFilterInputTypeOfTransportFilterInput>;
  /** TMA */
  truckMountedAttenuator?: InputMaybe<BooleanOperationFilterInput>;
  /** TMATid */
  truckMountedAttenuatorTime?: InputMaybe<FloatOperationFilterInput>;
  workItems?: InputMaybe<ListFilterInputTypeOfWorkItemFilterInput>;
};

/** Dagbok */
export type DiaryInput = {
  account?: InputMaybe<Scalars['String']>;
  address: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  consumptions?: InputMaybe<Array<ConsumptionInput>>;
  createdBy: Scalars['Int'];
  /** Beställare/Kund */
  customer?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  deviation?: InputMaybe<Scalars['String']>;
  emailReceivers?: InputMaybe<Array<Scalars['String']>>;
  endTime: Scalars['TimeSpan'];
  exceptionTimeReport?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  /** Fräsning */
  milling?: InputMaybe<Scalars['Boolean']>;
  millingSquaremeter?: InputMaybe<Scalars['Decimal']>;
  photos?: InputMaybe<Array<PhotoInput>>;
  selfControls?: InputMaybe<Array<SelfControlInput>>;
  startTime: Scalars['TimeSpan'];
  /** Sopmaskin */
  sweeper?: InputMaybe<Scalars['Boolean']>;
  /** SopmaskinTid */
  sweeperTime?: InputMaybe<Scalars['Float']>;
  team: TeamInput;
  teamId: Scalars['Int'];
  timeReports: Array<TimeReportInput>;
  trafficGuards?: InputMaybe<Scalars['Boolean']>;
  trafficGuardsTime?: InputMaybe<Scalars['Float']>;
  transport?: InputMaybe<Array<TransportInput>>;
  /** TMA */
  truckMountedAttenuator?: InputMaybe<Scalars['Boolean']>;
  /** TMATid */
  truckMountedAttenuatorTime?: InputMaybe<Scalars['Float']>;
  workItems?: InputMaybe<Array<WorkItemInput>>;
};

export type DiaryMetaOptions = {
  __typename?: 'DiaryMetaOptions';
  accounts: Array<Account>;
  teams: Array<Team>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ListFilterInputTypeOfConsumptionFilterInput = {
  all?: InputMaybe<ConsumptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ConsumptionFilterInput>;
  some?: InputMaybe<ConsumptionFilterInput>;
};

export type ListFilterInputTypeOfPhotoFilterInput = {
  all?: InputMaybe<PhotoFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PhotoFilterInput>;
  some?: InputMaybe<PhotoFilterInput>;
};

export type ListFilterInputTypeOfSelfControlFilterInput = {
  all?: InputMaybe<SelfControlFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SelfControlFilterInput>;
  some?: InputMaybe<SelfControlFilterInput>;
};

export type ListFilterInputTypeOfTeamMemberFilterInput = {
  all?: InputMaybe<TeamMemberFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TeamMemberFilterInput>;
  some?: InputMaybe<TeamMemberFilterInput>;
};

export type ListFilterInputTypeOfTimeReportFilterInput = {
  all?: InputMaybe<TimeReportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TimeReportFilterInput>;
  some?: InputMaybe<TimeReportFilterInput>;
};

export type ListFilterInputTypeOfTransportFilterInput = {
  all?: InputMaybe<TransportFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TransportFilterInput>;
  some?: InputMaybe<TransportFilterInput>;
};

export type ListFilterInputTypeOfWorkItemFilterInput = {
  all?: InputMaybe<WorkItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WorkItemFilterInput>;
  some?: InputMaybe<WorkItemFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type MutationBase = {
  __typename?: 'MutationBase';
  addDiary: Diary;
  updateDiary: Diary;
};


export type MutationBaseAddDiaryArgs = {
  entity: DiaryInput;
};


export type MutationBaseUpdateDiaryArgs = {
  entity: DiaryInput;
};

export type NullableOfAnswerOperationFilterInput = {
  eq?: InputMaybe<Answer>;
  in?: InputMaybe<Array<InputMaybe<Answer>>>;
  neq?: InputMaybe<Answer>;
  nin?: InputMaybe<Array<InputMaybe<Answer>>>;
};

export type Photo = {
  __typename?: 'Photo';
  diaryId?: Maybe<Scalars['Int']>;
  geolocation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  picture?: Maybe<Scalars['String']>;
};

export type PhotoFilterInput = {
  and?: InputMaybe<Array<PhotoFilterInput>>;
  diaryId?: InputMaybe<IntOperationFilterInput>;
  geolocation?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PhotoFilterInput>>;
  picture?: InputMaybe<StringOperationFilterInput>;
};

export type PhotoInput = {
  diaryId?: InputMaybe<Scalars['Int']>;
  geolocation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  picture?: InputMaybe<Scalars['String']>;
};

export type QueryBase = {
  __typename?: 'QueryBase';
  diaries: Array<Diary>;
  diaryMetaOptions: DiaryMetaOptions;
  employees: Array<TeamMember>;
  photos: Array<Photo>;
  users: Array<User>;
};


export type QueryBaseDiariesArgs = {
  where?: InputMaybe<DiaryFilterInput>;
};


export type QueryBaseEmployeesArgs = {
  where?: InputMaybe<TeamMemberFilterInput>;
};


export type QueryBasePhotosArgs = {
  where?: InputMaybe<PhotoFilterInput>;
};


export type QueryBaseUsersArgs = {
  where?: InputMaybe<UserFilterInput>;
};

/** Egenkontroll */
export type SelfControl = {
  __typename?: 'SelfControl';
  answer?: Maybe<Answer>;
  comment?: Maybe<Scalars['String']>;
  diaryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['Int']>;
};

/** Egenkontroll */
export type SelfControlFilterInput = {
  and?: InputMaybe<Array<SelfControlFilterInput>>;
  answer?: InputMaybe<NullableOfAnswerOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  diaryId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SelfControlFilterInput>>;
  question?: InputMaybe<StringOperationFilterInput>;
  questionId?: InputMaybe<IntOperationFilterInput>;
};

/** Egenkontroll */
export type SelfControlInput = {
  answer?: InputMaybe<Answer>;
  comment?: InputMaybe<Scalars['String']>;
  diaryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['Int']>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

/** Lag */
export type Team = {
  __typename?: 'Team';
  id: Scalars['Int'];
  name: Scalars['String'];
  teamMembers: Array<TeamMember>;
};

/** Lag */
export type TeamFilterInput = {
  and?: InputMaybe<Array<TeamFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TeamFilterInput>>;
  teamMembers?: InputMaybe<ListFilterInputTypeOfTeamMemberFilterInput>;
};

/** Lag */
export type TeamInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  teamMembers: Array<TeamMemberInput>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  teamId: Scalars['Int'];
};

export type TeamMemberFilterInput = {
  and?: InputMaybe<Array<TeamMemberFilterInput>>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TeamMemberFilterInput>>;
  teamId?: InputMaybe<IntOperationFilterInput>;
};

export type TeamMemberInput = {
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  teamId: Scalars['Int'];
};

export type TimeReport = {
  __typename?: 'TimeReport';
  diaryId: Scalars['Int'];
  endTime: Scalars['TimeSpan'];
  id: Scalars['Int'];
  startTime: Scalars['TimeSpan'];
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['Int']>;
};

export type TimeReportFilterInput = {
  and?: InputMaybe<Array<TimeReportFilterInput>>;
  diaryId?: InputMaybe<IntOperationFilterInput>;
  endTime?: InputMaybe<TimeSpanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TimeReportFilterInput>>;
  startTime?: InputMaybe<TimeSpanOperationFilterInput>;
  teamMember?: InputMaybe<TeamMemberFilterInput>;
  teamMemberId?: InputMaybe<IntOperationFilterInput>;
};

export type TimeReportInput = {
  diaryId: Scalars['Int'];
  endTime: Scalars['TimeSpan'];
  id: Scalars['Int'];
  startTime: Scalars['TimeSpan'];
  teamMember?: InputMaybe<TeamMemberInput>;
  teamMemberId?: InputMaybe<Scalars['Int']>;
};

export type TimeSpanOperationFilterInput = {
  eq?: InputMaybe<Scalars['TimeSpan']>;
  gt?: InputMaybe<Scalars['TimeSpan']>;
  gte?: InputMaybe<Scalars['TimeSpan']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']>>>;
  lt?: InputMaybe<Scalars['TimeSpan']>;
  lte?: InputMaybe<Scalars['TimeSpan']>;
  neq?: InputMaybe<Scalars['TimeSpan']>;
  ngt?: InputMaybe<Scalars['TimeSpan']>;
  ngte?: InputMaybe<Scalars['TimeSpan']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']>>>;
  nlt?: InputMaybe<Scalars['TimeSpan']>;
  nlte?: InputMaybe<Scalars['TimeSpan']>;
};

export type Transport = {
  __typename?: 'Transport';
  diaryId?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type TransportFilterInput = {
  and?: InputMaybe<Array<TransportFilterInput>>;
  diaryId?: InputMaybe<IntOperationFilterInput>;
  hours?: InputMaybe<FloatOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TransportFilterInput>>;
};

export type TransportInput = {
  diaryId?: InputMaybe<Scalars['Int']>;
  hours?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  passwordHash: Scalars['String'];
  reportReciever: Scalars['Boolean'];
  role: Scalars['String'];
  username: Scalars['String'];
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  passwordHash?: InputMaybe<StringOperationFilterInput>;
  reportReciever?: InputMaybe<BooleanOperationFilterInput>;
  role?: InputMaybe<StringOperationFilterInput>;
  username?: InputMaybe<StringOperationFilterInput>;
};

/** Arbetsmoment */
export type WorkItem = {
  __typename?: 'WorkItem';
  description?: Maybe<Scalars['String']>;
  diaryId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  millimeter?: Maybe<Scalars['Int']>;
  squaremeter?: Maybe<Scalars['Decimal']>;
};

/** Arbetsmoment */
export type WorkItemFilterInput = {
  and?: InputMaybe<Array<WorkItemFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  diaryId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  millimeter?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WorkItemFilterInput>>;
  squaremeter?: InputMaybe<DecimalOperationFilterInput>;
};

/** Arbetsmoment */
export type WorkItemInput = {
  description?: InputMaybe<Scalars['String']>;
  diaryId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  millimeter?: InputMaybe<Scalars['Int']>;
  squaremeter?: InputMaybe<Scalars['Decimal']>;
};

export type AddDiaryMutationVariables = Exact<{
  diary: DiaryInput;
}>;


export type AddDiaryMutation = { __typename?: 'MutationBase', addDiary: { __typename?: 'Diary', comment?: string | null, id: number } };

export type GetDiariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiariesQuery = { __typename?: 'QueryBase', diaries: Array<{ __typename?: 'Diary', id: number, date: any, address: string, createdBy: number }> };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'QueryBase', users: Array<{ __typename?: 'User', firstName: string, lastName: string, username: string, reportReciever: boolean }> };

export type GetMetaDataOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMetaDataOptionsQuery = { __typename?: 'QueryBase', diaryMetaOptions: { __typename?: 'DiaryMetaOptions', teams: Array<{ __typename?: 'Team', id: number, name: string, teamMembers: Array<{ __typename?: 'TeamMember', id: number, firstName: string, lastName: string, teamId: number }> }>, accounts: Array<{ __typename?: 'Account', id: number, name: string }> } };

export type GetEmployeesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmployeesQuery = { __typename?: 'QueryBase', employees: Array<{ __typename?: 'TeamMember', id: number, firstName: string, lastName: string }> };

export type GetDiariesPhotoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiariesPhotoQuery = { __typename?: 'QueryBase', photos: Array<{ __typename?: 'Photo', picture?: string | null }> };


export const AddDiaryDocument = gql`
    mutation addDiary($diary: DiaryInput!) {
  addDiary(entity: $diary) {
    comment
    id
  }
}
    `;
export type AddDiaryMutationFn = Apollo.MutationFunction<AddDiaryMutation, AddDiaryMutationVariables>;

/**
 * __useAddDiaryMutation__
 *
 * To run a mutation, you first call `useAddDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiaryMutation, { data, loading, error }] = useAddDiaryMutation({
 *   variables: {
 *      diary: // value for 'diary'
 *   },
 * });
 */
export function useAddDiaryMutation(baseOptions?: Apollo.MutationHookOptions<AddDiaryMutation, AddDiaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiaryMutation, AddDiaryMutationVariables>(AddDiaryDocument, options);
      }
export type AddDiaryMutationHookResult = ReturnType<typeof useAddDiaryMutation>;
export type AddDiaryMutationResult = Apollo.MutationResult<AddDiaryMutation>;
export type AddDiaryMutationOptions = Apollo.BaseMutationOptions<AddDiaryMutation, AddDiaryMutationVariables>;
export const GetDiariesDocument = gql`
    query getDiaries {
  diaries {
    id
    date
    address
    createdBy
  }
}
    `;

/**
 * __useGetDiariesQuery__
 *
 * To run a query within a React component, call `useGetDiariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiariesQuery(baseOptions?: Apollo.QueryHookOptions<GetDiariesQuery, GetDiariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiariesQuery, GetDiariesQueryVariables>(GetDiariesDocument, options);
      }
export function useGetDiariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiariesQuery, GetDiariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiariesQuery, GetDiariesQueryVariables>(GetDiariesDocument, options);
        }
export type GetDiariesQueryHookResult = ReturnType<typeof useGetDiariesQuery>;
export type GetDiariesLazyQueryHookResult = ReturnType<typeof useGetDiariesLazyQuery>;
export type GetDiariesQueryResult = Apollo.QueryResult<GetDiariesQuery, GetDiariesQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  users {
    firstName
    lastName
    username
    reportReciever
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetMetaDataOptionsDocument = gql`
    query getMetaDataOptions {
  diaryMetaOptions {
    teams {
      id
      name
      teamMembers {
        id
        firstName
        lastName
        teamId
      }
    }
    accounts {
      id
      name
    }
  }
}
    `;

/**
 * __useGetMetaDataOptionsQuery__
 *
 * To run a query within a React component, call `useGetMetaDataOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetaDataOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetaDataOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMetaDataOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetMetaDataOptionsQuery, GetMetaDataOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMetaDataOptionsQuery, GetMetaDataOptionsQueryVariables>(GetMetaDataOptionsDocument, options);
      }
export function useGetMetaDataOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMetaDataOptionsQuery, GetMetaDataOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMetaDataOptionsQuery, GetMetaDataOptionsQueryVariables>(GetMetaDataOptionsDocument, options);
        }
export type GetMetaDataOptionsQueryHookResult = ReturnType<typeof useGetMetaDataOptionsQuery>;
export type GetMetaDataOptionsLazyQueryHookResult = ReturnType<typeof useGetMetaDataOptionsLazyQuery>;
export type GetMetaDataOptionsQueryResult = Apollo.QueryResult<GetMetaDataOptionsQuery, GetMetaDataOptionsQueryVariables>;
export const GetEmployeesDocument = gql`
    query getEmployees {
  employees {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useGetEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
      }
export function useGetEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export type GetEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesQuery>;
export type GetEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesLazyQuery>;
export type GetEmployeesQueryResult = Apollo.QueryResult<GetEmployeesQuery, GetEmployeesQueryVariables>;
export const GetDiariesPhotoDocument = gql`
    query getDiariesPhoto {
  photos {
    picture
  }
}
    `;

/**
 * __useGetDiariesPhotoQuery__
 *
 * To run a query within a React component, call `useGetDiariesPhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiariesPhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiariesPhotoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDiariesPhotoQuery(baseOptions?: Apollo.QueryHookOptions<GetDiariesPhotoQuery, GetDiariesPhotoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiariesPhotoQuery, GetDiariesPhotoQueryVariables>(GetDiariesPhotoDocument, options);
      }
export function useGetDiariesPhotoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiariesPhotoQuery, GetDiariesPhotoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiariesPhotoQuery, GetDiariesPhotoQueryVariables>(GetDiariesPhotoDocument, options);
        }
export type GetDiariesPhotoQueryHookResult = ReturnType<typeof useGetDiariesPhotoQuery>;
export type GetDiariesPhotoLazyQueryHookResult = ReturnType<typeof useGetDiariesPhotoLazyQuery>;
export type GetDiariesPhotoQueryResult = Apollo.QueryResult<GetDiariesPhotoQuery, GetDiariesPhotoQueryVariables>;