import { t } from 'i18next'
import { MouseEventHandler } from 'react'

type ButtonProps = {
   text: string
   onClick: MouseEventHandler<HTMLButtonElement>
}

const Button = ({ text, onClick }: ButtonProps) => {
   return (
      <button
         className="bg-yellow-500 hover:bg-yellow-600 text-gray-700 min-w-fit w-20 font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline  mb-10"
         onClick={onClick}
         type="button"
      >
         {text}
      </button>
   )
}

export default Button
