import { t } from 'i18next'
import {
      useGetDiariesQuery,
      useGetDiariesPhotoQuery,
} from '../../../graphql/generated/schema'
import { Loader } from '../../shared/Loader'
import dayjs from 'dayjs'
import { IUser } from '../../../authentication/interfaces'
// import ImageHej from '../../Image'

type DiaryProps = {
      user: IUser | undefined
}
export const Diaries = ({ user }: DiaryProps) => {
      const { loading, error, data } = useGetDiariesQuery()
      // const { loading, error, data } = useGetDiariesPhotoQuery()
      const filteredDiaries = () => {
            if (user?.role?.toLowerCase() !== 'admin') {
                  if (data?.diaries?.filter((x) => x.createdBy === user?.id))
                        return data?.diaries?.filter(
                              (x) => x.createdBy === user?.id
                        )
            } else return data?.diaries
      }
      return (
            <>
                  <div className="grid-flow-row">
                        <h2 className="text-white font-bold text-xl">
                              {' '}
                              {t('headers.sentdiaries')}
                        </h2>
                  </div>
                  {loading ? (
                        <Loader />
                  ) : (
                        filteredDiaries()?.map((diary) => (
                              <div
                                    key={diary.id}
                                    className="text-white grid grid-flow-col auto-cols-max"
                              >
                                    <div className="pt-2 pr-5">
                                          {dayjs(diary.date).format(
                                                'YYYY-MM-DD'
                                          )}
                                    </div>
                                    <div className="pt-2">{diary.address}</div>
                              </div>
                        ))

                        // data?.photos.map((x) => <ImageHej data={x.picture} />)
                  )}
            </>
      )
}
