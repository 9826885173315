type stepTitleProps = {
      title: string
      page: string
}
const StepTitle = ({ title, page }: stepTitleProps) => {
      return (
            <div className="col-span-8">
                  <h1 className="text-2xl font-bold text-white">{title}</h1>

                  <h2 className="text-xl text-white font-bold">{`- ${page}`}</h2>
            </div>
      )
}
export default StepTitle
