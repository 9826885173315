import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { MouseEventHandler } from 'react'

type AddInputFieldBtnProps = {
      label: string
      classes: string
      onClick: MouseEventHandler<HTMLElement>
}
const AddInputFieldBtn = ({
      label,
      classes,
      onClick,
}: AddInputFieldBtnProps) => {
      return (
            <div
                  className={`cursor-pointer col-span-12 flex gap-1 items-center ${classes}`}
                  onClick={onClick}
            >
                  <PlusCircleIcon
                        height={'32px'}
                        width={'32px'}
                        className="col-span-1 min-w-7 min-h-7 max-w-10 max-h-10 text-yellow-500"
                  ></PlusCircleIcon>
                  <h2 className="col-span-10 block text-yellow-500 text-lg cursor-pointer">
                        {label}
                  </h2>
            </div>
      )
}

export default AddInputFieldBtn
