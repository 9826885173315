import dayjs from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { UseFormReset } from 'react-hook-form'
import { Answer, DiaryInput } from '../graphql/generated/schema'

export const newDiary = {
   id: 0,
   workItems: [
      {
         description: undefined,
         diaryId: undefined,
         id: undefined,
         millimeter: undefined,
         squaremeter: undefined,
      },
   ],
   consumptions: [
      {
         diaryId: undefined,
         id: undefined,
         name: undefined,
         ton: undefined,
      },
   ],
   selfControls:[
      {  
         diaryId: undefined,
         id: undefined,
         answer: undefined,
         comment: undefined,
         questionId:undefined,
         questionName:undefined
      },
   ],
   transport: [
      {
         id: undefined,
         name: undefined,
         diaryId: undefined,
         hours: undefined,
      },
   ],
}

export const autoFill = (reset: UseFormReset<DiaryInput>, setStep: Dispatch<SetStateAction<number>>) => {
   let fakeData: DiaryInput = {
      account: 'HejKompis',
      address: 'HejGatan 3',

      date: dayjs().format('YYYY-MM-DD'),
      endTime: dayjs().format('[PT]' + 'H' + '[H]' + 'MM' + '[M]'),

      id: 0,

      startTime: dayjs().format('[PT]' + 'H' + '[H]' + 'MM' + '[M]'),
      team: {
         id: 0,
         name: '',
         teamMembers: [],
      },
      teamId: 0,
      timeReports: [],
      workItems: [
         {
            description: undefined,
            diaryId: undefined,
            id: undefined,
            millimeter: undefined,
            squaremeter: undefined,
         },
      ],
      consumptions: [
         {
            diaryId: undefined,
            id: undefined,
            name: undefined,
            ton: undefined,
         },
      ],
      transport: [
         {
            id: undefined,
            name: undefined,
            diaryId: undefined,
            hours: undefined,
         },
      ],
      createdBy: 0,
   }
   reset(fakeData)
   setStep(4)
}
export const AddItemToCollection = (setItemCollection: React.Dispatch<React.SetStateAction<any[]>>, collection: any[], item: any) => {
   let col = collection
   col = [...col, item]
   setItemCollection(col)
}
export const RemoveItemFromCollection = (setItemCollection: React.Dispatch<React.SetStateAction<any[]>>, collection: any[], item: any) => {
   const copy = collection.filter((x) => x !== item)
   setItemCollection(copy)
}
