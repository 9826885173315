import { UseFormRegister } from 'react-hook-form'
import { DiaryInput } from '../../graphql/generated/schema'

type textInputProps = {
   label: string
   register: UseFormRegister<DiaryInput>
   registerName: any
   placeholder: string
   classes: string
}
const TextInput = ({ label, register, registerName, placeholder, classes }: textInputProps) => {
   return (
      <div className={`${classes}`}>
         <label className="block text-yellow-500 text-lg">
            {label}
            <input
               className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
               type="text"
               {...register(registerName)}
               placeholder={placeholder}
            />
         </label>
      </div>
   )
}

export default TextInput
