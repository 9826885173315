import { createContext, ReactNode, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
      IAuthenticateRequest,
      initialAuthContext,
      IAuthContext,
      IUser,
} from './interfaces'
import { useTranslation } from 'react-i18next'

const AuthContext = createContext(initialAuthContext)
export type AuthContextProviderProps = {
      children: ReactNode
}
export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
      const { t } = useTranslation()
      const [user, setUser] = useState<IUser | undefined>(() => {
            let userProfle = localStorage.getItem('userProfile')
            if (userProfle) {
                  return JSON.parse(userProfle)
            }
            return null
      })
      const navigate = useNavigate()
      const login = async (payload: IAuthenticateRequest) => {
            let response
            try {
                  response = await fetch(
                        `${process.env.REACT_APP_API_BASE_URL}/user/authenticate`,
                        {
                              mode: 'cors',
                              method: 'POST',
                              headers: {
                                    'Content-Type': 'application/json',
                              },
                              body: JSON.stringify(payload),
                        }
                  )
            } catch (error) {
                  console.log('There was an error', error)
            }

            if (response?.ok) {
                  let usrObj = await response.json()
                  localStorage.setItem('userProfile', JSON.stringify(usrObj))
                  setUser(usrObj)
                  navigate('/')
            } else {
                  let errorResponse = await response?.json()
                  let errorMessage = t(errorResponse?.detail)
                  toast.error(errorMessage)
            }
      }

      const logout = async () => {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/logout`)
            localStorage.removeItem('userProfile')
            setUser(undefined)
            navigate('/login')
      }

      const authContextValue: IAuthContext = {
            user: user,
            login: login,
            logout: logout,
      }

      return (
            <>
                  <AuthContext.Provider value={authContextValue}>
                        {children}
                  </AuthContext.Provider>
            </>
      )
}

export default AuthContext
