export const sv = {
   buttons: {
      login: 'Logga in',
      back: 'Tillbaka',
      newdiary: 'Ny dagbok',
      next: 'Nästa',
      cancel: 'Avbryt',
      addphoto: 'Lägg till foto & position',
      newphoto: 'Ta om bild & position',
      submit: 'Skicka',
      ok: 'Acceptera',
      removephoto: ' Ta bort bild',
   },
   headers: {
      diary: 'Dagboken',
      sentdiaries: 'Skickade dagböcker',
      consumption: 'Förbrukning',
      workperformed: 'Utfört Arbete',
      selfcontrol: 'Egenkontroll',
      submitdiary: 'Slutför och skicka in dagboken',
      additionalinfo: 'Övrig information',
      general: 'Övrigt',
      newtimereport: 'Ny tidrapportering',
      teamtime: 'Lagtid',
      wanttosubmitdiary: 'Vill du skicka in dagboken?',
   },
   login: {
      username: 'Användarnamn',
      password: 'Lösenord',
      error: 'Något gick fel vid inloggning',
      wrongusernamepassword: 'Fel användarnamn eller lösenord',
   },
   labels: {
      consumptions: 'Massa',
      workperformed: 'Utfört Arbete',
      ton: 'Ton',
      transport: 'Lastbil/maskin',
      newLine: 'Ny rad',
      milling: 'Fräsning',
      millingSquaremeter: 'm²',
      millimeter: 'mm',
      sweeper: 'Sopmaskin',
      truckMountedAttenuator: 'TMA',
      trafficGuards: 'Trafikvakter',
      date: 'Datum',
      account: 'Konto/AO',
      address: 'Adress',
      customer: 'Beställare/Kund',
      team: 'Lag',
      starttime: 'Starttid',
      endtime: 'Sluttid',
      exceptionTimeReport: 'Finns undantag i tidrapportering',
      deviation: 'Avvikelser',
      ok: 'OK',
      notapplicable: 'Ej aktuell',
      onedeviation: 'Avvikelse',
      comment: 'Kommentarer',
      position: 'Position',
      extraworker: 'Extra YA',
      hours: 'Timmar',
      time: 'Tid',
      emailreceivers: 'Mail mottagare',
      uploadphotos: 'Ladda upp bilder',
   },
   placeholders: {
      chooseaccount: 'Välj ett konto',
      chooseteam: 'Välj ett lag',
      chooseextraworker: 'Välj extra YA',
      date: 'åååå-mm-dd',
      time: '--:--',
   },
   paragraphs: {
      sametimeforall: 'Samma tid för alla i laget',
      cantbeedited: 'Du kan inte ändra den efter du har skickat in den',
      yes: 'Ja',
      no: 'Nej',
      sharelocation: 'Delning av platsinfo är inte aktiverat.',
      sharelocationinstructions: 'För att dela din platsinfo, gå till platsinställningar i din webläsare och aktivera platsdelning.',
   },
   self_control_questions: [
      {
         id: 18,
         title: 'Fräsning/justering korrekt utfört',
      },
      {
         id: 33,
         title: 'Klistring utförd',
      },
      {
         id: 20,
         title: 'Brunnsbetäckningar, kantsten rengjorda',
      },
      {
         id: 41,
         title: 'Försegling utförd med klister och sand',
      },
      {
         id: 47,
         title: 'Arbetsplatsen är städad',
      },
   ],
}
