import { t } from 'i18next'
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { DiaryInput, useGetUsersQuery } from '../../../graphql/generated/schema'
import { useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { AddItemToCollection, RemoveItemFromCollection } from '../../../helpers/dataHelper'
type SubmitDiaryProps = {
   control: Control<DiaryInput, any>
   errors: FieldErrors<DiaryInput>
   watch: UseFormWatch<DiaryInput>
   setValue: UseFormSetValue<DiaryInput>
}
const SubmitDiary = ({ control, errors, watch, setValue }: SubmitDiaryProps) => {
   const [emailReceivers, setEmailReceivers] = useState<string[]>([])
   const { data } = useGetUsersQuery()
   useEffect(() => {
      setValue('emailReceivers', emailReceivers)
   }, [JSON.stringify(emailReceivers)])
   useEffect(() => {}, [])

   const values = (item: string) => {
      if (emailReceivers?.find((y) => y === item)) {
         RemoveItemFromCollection(setEmailReceivers, emailReceivers, item)
      } else AddItemToCollection(setEmailReceivers, emailReceivers, item)
   }

   return (
      <>
         <div className="bg-neutral-700 col-span-12 rounded-lg w-full">
            <p className="p-5 text-center text-base leading-relaxed border-white text-white ">{t('paragraphs.cantbeedited')}</p>
         </div>
         <div className="col-span-12 flex flex-col">
            {data?.users
               ?.filter((x) => x.reportReciever)
               .map((x, i) => (
                  <div className={`flex gap-3 items-center py-1`}>
                     <label className="block text-white order-2 col-span-11 w-full text-left" onClick={() => values(x.username)}>
                        {`${x.firstName} ${x.lastName}`}
                     </label>
                     <span
                        className={`block order-1 w-4 h-4 border rounded border-yellow-500 col-span-1  
                                          ${emailReceivers?.find((y) => y === x.username) ? 'bg-yellow-500' : 'bg-neutral-700'}
                                          `}
                        onClick={() => values(x.username)}
                     >
                        {emailReceivers?.find((y) => y === x.username) && <CheckIcon color="black" />}
                     </span>
                  </div>
               ))}
         </div>
      </>
   )
}

export default SubmitDiary
