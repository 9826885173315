import { t } from 'i18next'
import { Control, useFieldArray, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { useContext } from 'react'
import AuthContext from '../../../authentication/AuthContextProvider'
import { DiaryInput } from '../../../graphql/generated/schema'
import AddInputFieldBtn from '../../shared/AddInputFieldBtn'

type WorkPerformedProps = {
   register: UseFormRegister<DiaryInput>
   control: Control<DiaryInput, any>
   setValue: UseFormSetValue<DiaryInput>
   errors: any
}
const WorkPerformed = ({ register, control, setValue, errors }: WorkPerformedProps) => {
   const { user } = useContext(AuthContext)
   const { fields, append, remove } = useFieldArray({
      control,
      name: 'workItems',
   })

   return (
      <>
         <ul className="col-span-12  ">
            {fields?.map((field, index) => (
               <>
                  <li className="grid grid-cols-12 mt-6" key={field.id}>
                     <div className={'col-span-12'}>
                        <label className="block text-yellow-500 text-lg">
                           {t('labels.workperformed')}
                           <textarea
                              rows={3}
                              {...register(`workItems.${index}.description`)}
                              className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                           />
                        </label>
                        {errors?.workItems && errors?.workItems[index]?.description && (
                           <p className="text-red-500">{errors?.workItems[index]?.description?.message ?? 'Fyll i detta fält'}</p>
                        )}
                     </div>
                     <div className={'col-start-2 col-span-4'}>
                        <label className="block text-yellow-500 text-lg">
                           {t('labels.millingSquaremeter')}
                           <input
                              {...register(`workItems.${index}.squaremeter`, {
                                 valueAsNumber: true,
                              })}
                              className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                              type="number"
                           />
                        </label>
                        {errors?.workItems && errors?.workItems[index]?.squaremeter && (
                           <p className="text-red-500">{errors?.workItems[index]?.squaremeter?.message ?? 'Fyll i detta fält'}</p>
                        )}
                     </div>
                     <div className={'col-start-8 col-span-4'}>
                        <label className="block text-yellow-500 text-lg">
                           {t('labels.millimeter')}
                           <input
                              {...register(`workItems.${index}.millimeter`, {
                                 valueAsNumber: true,
                              })}
                              className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                              type="number"
                           />
                           {/* <button
                                                            type="button"
                                                            onClick={() =>
                                                                  remove(index)
                                                            }
                                                      >
                                                            Delete
                                                      </button> */}
                        </label>

                        {errors?.workItems && errors?.workItems[index]?.millimeter && (
                           <p className="text-red-500">{errors?.workItems[index]?.millimeter?.message ?? 'Fyll i detta fält'}</p>
                        )}
                     </div>
                  </li>
               </>
            ))}
         </ul>

         <AddInputFieldBtn
            label={t('labels.newLine')}
            classes={''}
            onClick={() => {
               append({
                  description: undefined,
                  diaryId: undefined,
                  id: undefined,
                  millimeter: undefined,
                  squaremeter: undefined,
               })
            }}
         ></AddInputFieldBtn>
      </>
   )
}

export default WorkPerformed
