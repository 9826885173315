import { t } from 'i18next'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DiaryInput, PhotoInput, TimeReportInput, useAddDiaryMutation } from '../../../graphql/generated/schema'
import { getStepValidationNames } from '../../../helpers/stepLogic'
import { autoFill, newDiary } from '../../../helpers/dataHelper'
import { LogoBanner } from '../../logobanner/LogoBanner'
import Button from '../../shared/Button'
import SaveModal from '../../shared/SaveModal'
import SecondaryButton from '../../shared/SecondaryButton'
import { Diaries } from '../overview/Diaries'
import AdditionalInfo from './AdditionalInfo'
import Consumption from './Consumption'
import Header from './Header'
import NewTimeReport from './NewTimeReportForm'
import SelfControl from './SelfControl'
import WorkPerformed from './WorkPerformed'
import AuthContext from '../../../authentication/AuthContextProvider'
import { registerCustomFields } from '../../../helpers/customFields'
import { Loader } from '../../shared/Loader'
import ErrorModal from '../../shared/ErrorModal'
import SubmitDiary from './SubmitDiary'

const Stepper = () => {
   const {
      trigger,
      watch,
      handleSubmit,
      register,
      setValue,
      reset,
      control,
      formState: { errors },
   } = useForm<DiaryInput>()

   const [addDiaryMutation, { data, loading, error }] = useAddDiaryMutation()
   const [step, setStep] = useState<number>(0)
   const [show, setShow] = useState(false)
   const [isLastStepAndValid, setIsLastStepAndValid] = useState(false)
   const [upLoadedPictures, setUploadedPictures] = useState<PhotoInput[]>([])

   useEffect(() => {
      if (step === 6 && (watch('emailReceivers')?.length ?? 0) > 0) {
         setIsLastStepAndValid(true)
      } else setIsLastStepAndValid(false)
   }, [step, watch('emailReceivers')])

   const validateStepAndMove = async (step: number) => {
      let keys = getStepValidationNames(step - 1)
      let result = await trigger(keys as any)
      if (result) {
         setStep(step)
      }
   }

   const { user } = useContext(AuthContext)
   const StepComponent = () => {
      switch (step) {
         case 1:
            return <NewTimeReport watch={watch} setValue={setValue} register={register} control={control} errors={errors} />
         case 2:
            return <WorkPerformed register={register} control={control} setValue={setValue} errors={errors} />
         case 3:
            return <Consumption setValue={setValue} register={register} control={control} errors={errors} watch={watch} />
         case 4:
            return <SelfControl setValue={setValue} register={register} control={control} errors={errors} watch={watch} />
         case 5:
            return (
               <AdditionalInfo
                  register={register}
                  setValue={setValue}
                  control={control}
                  watch={watch}
                  setUploadedPictures={setUploadedPictures}
                  upLoadedPictures={upLoadedPictures}
               />
            )
         case 6:
            return <SubmitDiary control={control} errors={errors} watch={watch} setValue={setValue}></SubmitDiary>
            break
      }
   }
   const createTimeReports = (diary: DiaryInput): Array<TimeReportInput> => {
      return diary.team.teamMembers?.map((x) => ({
         diaryId: watch('id'),
         endTime: diary.endTime,
         startTime: diary.startTime,
         id: 0,
         teamMember: x,
         teamMemberId: x.id,
      }))
   }
   const onSubmit = async (diary: DiaryInput) => {
      diary.teamId = watch('teamId')
      diary.timeReports = createTimeReports(diary)
      diary.createdBy = user?.id ? user?.id : 0
      diary.selfControls = watch('selfControls')?.filter(x => x.answer)
      diary.photos = diary.photos?.concat(upLoadedPictures)
      await addDiaryMutation({ variables: { diary } })
   }

   useEffect(() => {
      setShow(true)
   }, [error, data])
   useEffect(() => {
      if (step === 0) reset(newDiary)
      if (step > 0) registerCustomFields(register)
   }, [step])
   return (
      <div>
         {loading && (
            <div className=" z-50 bg-neutral-700 border border-white text-white p-6 rounded text-center min-w-xs max-w-lg fixed top-10 left-10 right-10">
               <Loader />
            </div>
         )}
         {error && show ? <ErrorModal setShow={setShow} title={'Submitting error!'} subTitle={error.message} primaryBtnText={'Stäng'} zIndex={'z-50'} /> : null}
         {data && show ? (
            <SaveModal setShow={setShow} setStep={setStep} title={'Sparning lyckades'} subTitle={''} primaryBtnText={'Till start'} zIndex={'z-50'}></SaveModal>
         ) : null}

         {step === 0 ? (
            <>
               <div className={`pl-10`}>
                  <div className="grid-flow-row mt-10">
                     <h1 className="text-2xl font-bold text-white">{t('headers.diary')}</h1>
                  </div>
                  <div className="grid-flow-row mt-10">
                     <Button text={t('buttons.newdiary')} onClick={() => setStep(1)} />
                     {/* <Button text={'TestData'} onClick={() => autoFill(reset, setStep)} /> */}
                  </div>
                  <Diaries user={user} />
               </div>
               <LogoBanner />
            </>
         ) : (
            <>
               <form onSubmit={handleSubmit(onSubmit)} className="pb-10 grid grid-cols-12 gap-y-6 pl-5 pr-5">
                  <Header step={step} setStep={validateStepAndMove} />
                  {StepComponent()}

                  <div className="col-span-12 flex justify-between">
                     <SecondaryButton
                        text={step === 1 ? t('buttons.cancel') : t('buttons.back')}
                        onClick={() => setStep((prevState) => prevState - 1)}
                     ></SecondaryButton>

                     {isLastStepAndValid && (
                        <button
                           className="bg-yellow-500 hover:bg-yellow-600 text-gray-700 min-w-fit w-20 font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline  mb-10"
                           type="submit"
                        >
                           {t('buttons.submit')}
                        </button>
                     )}
                  </div>
               </form>
            </>
         )}
      </div>
   )
}

export default Stepper
