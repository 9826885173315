export interface IAuthContext {
      user?: IUser
      login?: (payload: IAuthenticateRequest) => Promise<void>
      logout?: (payload: IAuthenticateRequest) => Promise<void>
}

export interface IUser {
      id: number
      firstName: string
      lastName: string
      username: string
      token: string
      role: string
}

export const initialAuthContext: IAuthContext = {
      user: undefined,
      login: undefined,
      logout: undefined,
}

export interface IAuthenticateRequest {
      username: string
      password: string
}

export interface IRegisterRequest {
      firstName: string
      lastName: string
      userName: string
      password: string
      role: string
}

export interface IUpdateRequest {
      firstName: string
      lastName: string
      userName: string
      password: string
      role: string
}
