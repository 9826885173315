import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { Answer, DiaryInput } from '../../graphql/generated/schema'

type SelfControlQuestionProps = {
   label: string
   index: number
   questionId: number
   classes?: string
   register: UseFormRegister<DiaryInput>
   setValue: UseFormSetValue<DiaryInput>
   watch: UseFormWatch<DiaryInput>

}
const SelfControlQuestion = ({ label, classes, register, watch, setValue, index, questionId }: SelfControlQuestionProps) => {
   const { t } = useTranslation()

   
   useEffect(() => {
      setValue(`selfControls.${index}`, {question: label, questionId: questionId})
   }, [])


   function checkBoxValue(answer: Answer): void {
      switch (answer) {
         case Answer.Ok:
            setValue(`selfControls.${index}.answer`, Answer.Ok); setValue(`selfControls.${index}.comment`, "") 
            break;
         case Answer.NotApplicable:
            setValue(`selfControls.${index}.answer`, Answer.NotApplicable); setValue(`selfControls.${index}.comment`, "")
            break;
         case Answer.Deviation:
            setValue(`selfControls.${index}.answer`, Answer.Deviation)
            break;
         default:
            setValue(`selfControls.${index}.answer`, Answer.Ok)
      }
   }

   return (
      <div className={`${classes}`}>
         <div className="col-span-12">
            <label className="text-white text-xl font-bold">{label}</label>
         </div>
         <div className="col-span-4 flex gap-2 items-center">

            <span
               className={`block w-4 h-4 border rounded border-yellow-500 col-span-1  
                         ${watch(`selfControls.${index}.answer`) === Answer.Ok ? 'bg-yellow-500' : 'bg-neutral-700'}
                        `}
               onClick={() => {
                  checkBoxValue(Answer.Ok)

               }}

            >
               {watch(`selfControls.${index}.answer`) === Answer.Ok && <CheckIcon />}
            </span>
            <label
               className="text-yellow-500 text-lg"
               onClick={() => {
                  checkBoxValue(Answer.Ok)
               }}
            >
               {t('labels.ok')}
            </label>

         </div>
         <div className="col-span-4 flex gap-2 items-center">
            <span
               className={`block w-4 h-4 border rounded border-yellow-500 col-span-1  
                        ${watch(`selfControls.${index}.answer`) === Answer.NotApplicable ? 'bg-yellow-500' : 'bg-neutral-700'}
                        `}
               onClick={() => {
                  checkBoxValue(Answer.NotApplicable)
               }}
            >
                    {watch(`selfControls.${index}.answer`) === Answer.NotApplicable && <CheckIcon />}
            </span>
            <label
               className="text-yellow-500 text-lg"
               onClick={() => {
                  checkBoxValue(Answer.NotApplicable)
               }}
            >
               {t('labels.notapplicable')}
            </label>
         </div>
         <div className="col-span-4 flex gap-2 items-center">
            <span
               className={`block w-4 h-4 border rounded border-yellow-500 col-span-1  
                        ${watch(`selfControls.${index}.answer`) === Answer.Deviation ? 'bg-yellow-500' : 'bg-neutral-700'}
                        `}
               onClick={() => {
                  checkBoxValue(Answer.Deviation)
               }}
            >
                   {watch(`selfControls.${index}.answer`) === Answer.Deviation && <CheckIcon />}
            </span>
            <label
               className="text-yellow-500 text-lg"
               onClick={() => {
                  checkBoxValue(Answer.Deviation)
               }}
            >
               {t('labels.onedeviation')}
            </label>
         </div>
         {watch(`selfControls.${index}.answer`) === Answer.Deviation && (
            <div className="col-span-12 flex gap-2 items-center mt-3">
               <input
                  {...register(`selfControls.${index}.comment`)}
                  className="w-full shadow appearance-none border rounded py-2 px-3 bg-neutral-700 border-yellow-500 text-white leading-tight focus:outline-none focus:shadow-outline "
                  type="text"
                  placeholder={t('labels.onedeviation') as string}
               />
            </div>
         )}
      </div>
   )
}
export default SelfControlQuestion
