export const en = {
   buttons: {
      login: 'Login',
      back: 'Back',
      newdiary: 'New diary',
      next: 'Next',
      cancel: 'Cancel',
      addphoto: 'Add photo & position',
      newphoto: 'New photo & position',
      submit: 'Submit',
      ok: 'Accept',
      removephoto: 'Ta bort bild',
   },
   headers: {
      diary: 'Diary',
      sentdiaries: 'Sent diaries',
      consumption: 'Consumption',
      workperformed: 'Work performed',
      selfcontrol: 'Self control',
      submitdiary: 'Send diary',
      general: 'Other',
      additionalinfo: 'Additional information',
      newtimereport: 'New time report',
      teamtime: 'Team time',
      wanttosubmitdiary: 'Do you want to submit the diary?',
   },
   login: {
      username: 'Username',
      password: 'Password',
      error: 'Something went wrong',
      wrongusernamepassword: 'Wrong username or password',
   },
   labels: {
      consumptions: 'Pulp',
      workperformed: 'Work performed',
      ton: 'Tons',
      transport: 'Truck/machine ',
      newLine: 'New line',
      milling: 'Milling',
      millingSquaremeter: 'm²',
      millimeter: 'mm',
      sweeper: 'Sweeper',
      truckMountedAttenuator: 'TMA',
      trafficGuards: 'Trafic Guards',
      date: 'Date',
      account: 'Account',
      address: 'Address',
      customer: 'Customer',
      team: 'Team',
      starttime: 'Start time',
      endtime: 'End time',
      exceptionTimeReport: 'Is there exceptions in the time report?',
      deviation: 'Deviations',
      comment: 'Comments',
      position: 'Position',
      extraworker: 'Extra YA',
      hours: 'Timmar',
      time: 'Tid',
      emailreceivers: 'Mail mottagare',
      uploadphotos: 'Ladda upp bilder',
   },
   placeholders: {
      chooseaccount: 'Choose an account',
      chooseteam: 'Choose a team',
      chooseextraworker: 'Choose a worker',
      date: 'yyyy-mm-dd',
      time: '--:--',
   },
   paragraphs: {
      sametimeforall: 'Same time for everyone in the team',
      cantbeedited: "Note that after being sent it can't be changed",
      yes: 'Yes',
      no: 'No',
      sharelocation: 'Sharing of your location is disabled.',
      sharelocationinstructions: 'To share your location, go to location settings in your browser and enable location sharing.',
   },
   self_control_questions: [
      {
         id: 18,
         title: 'Fräsning/justering korrekt utfört',
      },
      {
         id: 33,
         title: 'Klistring utförd',
      },
      {
         id: 20,
         title: 'Brunnsbetäckningar, kantsten rengjorda',
      },
      {
         id: 41,
         title: 'Försegling utförd med klister och sand',
      },
      {
         id: 47,
         title: 'Arbetsplatsen är städad',
      },
   ],
}
