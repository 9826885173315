import Layout from './components/shared/Layout'
import { Route, Routes } from 'react-router-dom'
import { AuthContextProvider } from './authentication/AuthContextProvider'
import Login from './components/pages/Login'
import ProtectedRoute from './authentication/ProtectedRoute'
import { LogoBanner } from './components/logobanner/LogoBanner'
import {
      ApolloClient,
      ApolloProvider,
      createHttpLink,
      InMemoryCache,
} from '@apollo/client'
import Stepper from './components/pages/diary/Stepper'

const link = createHttpLink({
      uri: process.env.REACT_APP_GRAPH_QL_URL,
      // credentials: 'include',
})

const client = new ApolloClient({
      cache: new InMemoryCache(),
      link,
})

const App = () => {
      return (
            <div className="bg-neutral-800 h-full min-h-screen font-sans">
                  <AuthContextProvider>
                        <ApolloProvider client={client}>
                              <Layout>
                                    <Routes>
                                          <Route
                                                path="/"
                                                element={
                                                      <ProtectedRoute>
                                                            <Stepper />
                                                      </ProtectedRoute>
                                                }
                                          />
                                          <Route
                                                path="/login"
                                                element={
                                                      <>
                                                            <Login />
                                                            <LogoBanner />
                                                      </>
                                                }
                                          />
                                    </Routes>
                              </Layout>
                        </ApolloProvider>
                  </AuthContextProvider>
            </div>
      )
}

export default App
